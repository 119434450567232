<template>
  <SearchForm v-if="!isPage" ref="searchFormRef" @list-update="handlePageChange(1)" />
  <NGrid :x-gap="isPage ? 20 : 12" :col="24">
    <NGi :span="isPage ? 6 : 9">
      <!-- resourceEnum.EXERCISES_RESOURCE_TYPE -->
      <div :style="{ border: isPage ? 'none' : '1px solid #f2f2f2' }">
        <LabelList
          style="overflow-y:auto;"
          ref="labelListRef"
          label-list-title="标签列表"
          :label-type="2"
          show-no-label
          @list-update="handlePageChange(1)"

          :label-content-height="labelContentHeight"
        />
      </div>
    </NGi>
    <NGi :span="isPage ? 18 : 15">
      <div
        style="background-color: #fff;"
        :style="{ padding: isPage ? '20px 24px' : '0' }"
      >
        <SearchForm v-if="isPage" ref="searchFormRef" :current-task="currentTask" @list-update="handlePageChange(1)" />
        <div v-if="isPage" style="margin-bottom: 15px;">
          <NSpace justify="space-between">
            <NSpace>
              <NButton
                v-if="ownUrlPermission('exercises/batch-delete')"
                :disabled="selectedIds.length === 0"
                @click="toDel('multiple')"
              >批量删除</NButton>
              <NButton
                v-if="ownUrlPermission('exercises/batch-export-exercises')&&currentTask===false"
                tag="a"
                :href="batExportUrl"
                :target="batExportUrl === 'javascript:;' ? '_self' : '_blank'"
                :disabled="selectedIds.length === 0"
              >批量导出</NButton>
            </NSpace>
            <NSpace>
              <NButton v-if="ownUrlPermission('exercises/distribute')" @click="toDistribute">分发</NButton>
              <NButton v-if="ownUrlPermission('exercises/batch-import-exercises')&&currentTask===false" type="primary" @click="handleClickBatImport">批量新增习题</NButton>
              <NDropdown
            width="trigger"
            trigger="click"
            :options="addBtnOptions"
            @select="handleAdd"
          >
            <NButton
              v-if="ownUrlPermission('teaching/create')"
              style="float: right; margin-left: 15px;"
              type="primary"
              icon-placement="right"
            >
              <template #default>新增习题</template>
              <template #icon>
                <NIcon color="#fff" size="18">
                  <ChevronDown />
                </NIcon>
              </template>
            </NButton>
          </NDropdown>
            </NSpace>
          </NSpace>
        </div>
        <NTabs
          type="line"
          @before-leave="handleTabBeforeLeave"
          @update:value="handleTabChange"
          :class="{ 'tab-hide': exerciseTypeKey === exerciseAddTypeEnumVal.TEACHING_RESOURCE_TYPE || exerciseTypeKey === exerciseAddTypeEnumVal.EXERCISES_RESOURCE_TYPE }"
        >
        <NTabPane
        v-for="item in addBtnOptions" :key="item.key"
        :name="item.key"
        :tab="item.label"
      >
        <NAlert
          v-show="selectedIds.length > 0"
          style="margin-bottom: 10px;"
          type="primary"
        >
          已选择 <span :style="{ color: themeSettings.appThemeColor }">{{ selectedIds.length }}</span> 项数据
        </NAlert>

        <NDataTable
          :columns="columns"
          :data="tableData"
          :row-key="row => row.id"
          remote
          :pagination="pagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          :checked-row-keys="selectedIds"
          @update:checked-row-keys="handleSelectChange"

          :max-height="tableContentHeight"
        />
      </NTabPane>
      </NTabs>
      </div>
    </NGi>
  </NGrid>

  <Preview ref="previewRef" />
  <!-- resourceEnum.EXERCISES_RESOURCE_TYPE -->
  <Distribute
    ref="distributeRef"
    distribute-title="分发习题"
    :label-type="2"
    :distribute-fn="distributeExercise"
  />
  <BatImportExercisesModal
    ref="batImportRef"
    bat-import-type="toExercises"
    :bat-import-fn="batImportExercise"
    @list-update="handleBatImportSuccess"
  />
  <PageLoading :loading="loading" />
</template>

<script setup>
  import { h, ref, reactive, onMounted, computed } from 'vue';
  import { NSpace, NButton, NTime, useMessage, useDialog } from 'naive-ui';
  import { useRouter } from 'vue-router';
  
  import resourceTypes, { resourceEnum as exerciseAddTypeEnumVal } from '@/enumerators/exercises-types-map.js';
  import { ChevronDown } from '@vicons/ionicons5';
  import PageLoading from '@/components/PageLoading/index.vue';
  import LabelList from '@/components/LabelList/LabelList.vue';
  import SearchForm from './components/SearchForm.vue';
  import Distribute from '@/components/Distribute/Distribute.vue';
  import BatImportExercisesModal from '@/components/BatImportExercisesModal/BatImportExercisesModal.vue';
  import Preview from '@/views/exercises/index/list/components/preview.vue';

  import {
    getExerciseList,
    delExercise,
    batDelExercise,
    distributeExercise,
    batImportExercise
  } from '@/api/exercises.js';
  import questionTypeMap from '@/enumerators/question-type-map.js';
  import taskTypeMap from '@/enumerators/task-type-map.js';
  import themeSettings from '@/settings/theme-settings.js'; 
  import env from '@/settings/env.js';
  import { getToken } from '@/utils/auth.js';

  import { resStatusEnum } from '@/enumerators/http.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';

  const { SUCCESS } = resStatusEnum;
  const router = useRouter();
  const dialog = useDialog();
  const message = useMessage();
  const loading = ref(false);
  const ResourceType = ref(1)

  const props = defineProps({
    isPage: {
      type: Boolean,
      default: false
    },
    exerciseHomework: {
      type: Number,
      default: 0
    },
    typeKey: {
      type: Number,
      default: 0
    },
    labelContentHeight: {
      type: String,
      default: 'auto'
    },
    tableContentHeight: {
      type: String,
      default: 'auto'
    }
  });

  const addBtnOptions = ref([])
  
  if(ownUrlPermission('exercises/create')&&props.exerciseHomework==1){
    addBtnOptions.value.push({label:'应试题目',key:'1'})
  }
  
  // 实训作业模块是否展示 true:不展示 false:展示
  if(props.exerciseHomework==2){
    addBtnOptions.value.push({label:'实训作业',key:'2'})
  }
  
  if(props.exerciseHomework==0){
    addBtnOptions.value.push({label:'应试题目',key:'1'})
    addBtnOptions.value.push({label:'实训作业',key:'2'})
  }

  function handleTabBeforeLeave() {
    return !loading.value;
  }

  // 当前是否实训作业
  const currentTask = ref(false)

  function handleTabChange(key) {
    if(key==2){
      currentTask.value = true
    }else{
      currentTask.value = false
    }
    addBtnOptions.value.forEach(item=>{
      if(key==item.key){
        ResourceType.value = item.key;
      }
    })
    handlePageChange(1);
  }

  const handleAdd = key => {
    if(key=='1'){
      router.push('add');
    }else{
      router.push('homework-add');
    }
  };

  const labelListRef = ref(null);
  const searchFormRef = ref(null);
  const previewRef = ref(null);

  const selectedIds = ref([]);
  const columns = [
    { type: 'selection' },
    { title: '试题 ID', key: 'id', width: 60 },
    { title: '题干', key: 'title' },
    {
      title: '题型',
      key: 'type',
      width: 65,
      render: row => h(
        'span',
        null,
        getTypeName(row.type)
      )
    },
    { title: '创建者', key: 'admin_username', width: 100 },
    {
      title: '更新日期',
      width: 170,
      render: row => h(
        NTime,
        {
          time: (Number(row.update_time) || 0) * 1000
        }
      )
    }
  ];
  if (props.isPage) {
    columns.push({
      title: '操作',
      width: 105,
      render: row => h(
        NSpace,
        null,
        {
          default: () => {
            const tempArr = [];
            if (ownUrlPermission('exercises/detail')) {
              tempArr.push(h(NButton, { text: true, type: 'primary', onClick: () => previewRef.value.view(row.id) }, { default: () => '查看' }));
            }
            if (ownUrlPermission('exercises/update')) {
              if(ResourceType.value==1){
                tempArr.push(h(NButton, { text: true, type: 'primary', onClick: () => router.push(`edit?id=${ row.id }`) }, { default: () => '编辑' }));
              }else{
                tempArr.push(h(NButton, { text: true, type: 'primary', onClick: () => router.push(`homework-add?id=${ row.id }`) }, { default: () => '编辑' }));
              }
            }
            if (ownUrlPermission('exercises/delete')) {
              tempArr.push(h(NButton, { text: true, type: 'error', onClick: () => toDel('single', row) }, { default: () => '删除' }));
            }
            return tempArr;
          }
        }
      )  
    });
  }

  function handleSelectChange(keys) {
    if (!props.isPage && props.typeKey === exerciseAddTypeEnumVal.EXERCISES_RESOURCE_TYPE) { // 弹框选择的实训作业 - 单选
      keys.length > 1 && (keys = [keys.pop()]);
    }
    selectedIds.value = keys;
  }
  
  const pagination = reactive({
    page: 1,
    itemCount: 0,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true
  });
  const excludeExerciseIds = [];
  const tableData = ref([]);
  const getTypeName = type => {
    if(currentTask.value){
      return taskTypeMap[type];
    }else{
      return questionTypeMap[type];
    }
  }
 
  const updateItemCount = count => {
    pagination.itemCount = Number(count) || 0;
  };
  const handlePageChange = page => {
    pagination.page = page;
    updateTableData();
  };
  const handlePageSizeChange = pageSize => {
    pagination.pageSize = pageSize;
    handlePageChange(1);
  };
  const getReqParams = () => {
    const {
      page,
      pageSize: page_size
    } = pagination;
    const params = {
      ...searchFormRef.value.getSearchParams(),
      page,
      page_size,
      type_status:ResourceType.value
    };
    const ids = labelListRef.value.getSelectedIds();
    if (ids.includes('0')) { // “未设置标签”
      params['Exercises[labels_num]'] = 0;
    } else {
      const labelsStr = ids.join(',');
      labelsStr && (params['Exercises[labels]'] = labelsStr);
    }
    excludeExerciseIds.length > 0 && (params['Exercises[ids]'] = excludeExerciseIds.join(','));
    return params;
  };
  const updateTableData = () => {
    loading.value = true;
    getExerciseList(getReqParams()).then(res => {
      if (res.code === SUCCESS) {
        tableData.value = res.data.list || [];
        updateItemCount(res.data.total);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };
  onMounted(() => {
    if (props.isPage) {
      excludeExerciseIds.splice(0);
      updateTableData();
    }
  });
  const clearSelectedIds = () => {
    selectedIds.value = [];
  };
  const toDel = (type, row) => {
    switch (type) {
      case 'single':
        const { id, title } = row;
        dialog.warning({
          title: '删除确认',
          content: () => h(
            'div',
            { style: { 'word-break': 'break-all' } },
            `确定删除习题“${title}”？`
          ),
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            loading.value = true;
            delExercise({ 'Exercises[id]': id }).then(res => {
              loading.value = false;
              if (res.code === SUCCESS) {
                message.success('删除成功');
                handlePageChange(1);
                labelListRef.value.updateLabelList();
                clearSelectedIds();
              }
            }).catch(err => {
              loading.value = false;
            });
          },
          onNegativeClick: () => {}
        });
        break;
      case 'multiple':
        const ids = selectedIds.value;
        dialog.warning({
          title: '删除确认',
          content: `确定删除${ids.length}个习题？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            loading.value = true;
            batDelExercise({ 'Exercises[ids]': ids }).then(res => {
              loading.value = false;
              if (res.code === SUCCESS) {
                message.success('批量删除成功');
                handlePageChange(1);
                labelListRef.value.updateLabelList();
                clearSelectedIds();
              }
            }).catch(err => {
              loading.value = false;
            });
          },
          onNegativeClick: () => {}
        });
        break;
    }
  };
  const batExportUrl = computed(() => {
    let url = 'javascript:;';
    if (selectedIds.value.length > 0) {
      const params = [];
      params.push(`Authorization=${ getToken() }`);
      params.push(`Exercises[ids]=${ selectedIds.value.join(',') }`);
      url = `${ env.apiUrlPrefix }/backend/web/exercises/batch-export-exercises?${ params.join('&') }`;
    }
    return url;
  });

  const distributeRef = ref(null);
  const toDistribute = () => {
    distributeRef.value.openModal();
  };

  const batImportRef = ref(null);
  const handleClickBatImport = () => {
    batImportRef.value.openModal();
  };
  const handleBatImportSuccess = () => {
    handlePageChange(1);
    labelListRef.value.updateLabelList();
  };

  const getSelectedExerciseIds = () => selectedIds.value;
  const initData = ({ filterIds = [], initIds = [], key } = {}) => {
    excludeExerciseIds.splice(0);
    excludeExerciseIds.push(...filterIds);
    selectedIds.value = [...initIds];
    exerciseTypeKey.value = key;
    switch (exerciseTypeKey.value) {
      case undefined:
        handlePageChange(1);
        break;
      default:
        handleTabChange(String(key));
    }
  };

  const exerciseTypeKey = ref(0);

  defineExpose({
    getSelectedExerciseIds,
    initData
  });
</script>

<style lang="less" scoped>
  .tab-hide {
    :deep(.n-tabs-nav) {
      display: none;
    }
    :deep(.n-tab-pane) {
      padding-top: 0;
    }
  }
</style>